import { withZepetofulContent } from '@zep/next/server';

import { ZepetofulEntries } from '../../consts';
import { LandingPageView } from '../../views';

const LandingPage = () => {
  return <LandingPageView />;
};

export default LandingPage;

export const getStaticProps = withZepetofulContent(
  ZepetofulEntries.LANDING_PAGE,
);
