import { SideTabItem } from '../types/GlobalTypes';

export const AssetManagerTabs: Array<SideTabItem> = [
  {
    name: 'myAssets',
    pageName: 'manage_my_assets',
    link: '/manage/my-assets',
  },
  {
    name: 'sales',
    pageName: 'manage_details_history',
    link: '/manage/sales',
  },
];

export const ManageQueryKeys = {
  /**
   * 내가 제작한 앱 리스트를 가져오는 API(앱/게임 에셋 업로드시 사용)
   */
  MY_APP_LIST_GET: 'my-apps',

  /**
   * 후원 내역 가져오는 API
   */
  MY_DONATIONS: 'my-donations',
  MY_ASSETS: 'my-assets',
  MY_SALES: 'my-sales',
};
